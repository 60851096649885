import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import store from '@/store' // Dependency cycle. But should not make troubles in that case, so disabled eslint
import { canNavigate } from '@/libs/acl/routeProtection'

// Route
import authRoutes from './routes/auth'
import sharedRoutes from './routes/shared'
import hostRoutes from './routes/host'
import tenantRoutes from './routes/tenant'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    if (to.path !== from.path) {
      return { x: 0, y: 0 }
    }

    return null
  },
  routes: [
    ...authRoutes,
    ...sharedRoutes,
    ...hostRoutes,
    ...tenantRoutes,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const allowAnonymous = to.matched.some(record => record.meta.allowAnonymous)
  if (allowAnonymous) { // route allows anonymous access
    return next()
  }

  store.dispatch('auth/checkAccess', true).then(statusCode => {
    if (statusCode === 'OK') {
      if (canNavigate(to)) {
        next()
      } else {
        router.push({ name: 'NoAccess' })
      }
    } else {
      store.dispatch('auth/signin', to.path)
        .catch(e => {
          Vue.$log.debug('Failed to signin.', e)
          router.push({ name: 'NoAccess' })
        })
    }
  })

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

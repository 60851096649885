import Vue from 'vue'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'

// import 'moment/locale/de'

// use en globally
moment.locale('en')

Vue.prototype.$moment = moment

export default moment

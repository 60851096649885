export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/Faq.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true,
      pageTitle: 'FAQ',
      breadcrumb: [
        {
          text: 'FAQ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/ProActiveCheckups',
    name: 'dashboard-proActiveCheckups',
    component: () => import('@/views/dashboard/proActiveCheckup/dashboard.vue'),
    meta: {
      action: 'Core.SystemCheckups',
      pageTitle: 'ProActive Checkups',
    },
  },
  {
    path: '/asup/reference-list/avamar',
    name: 'reference-list.avamar',
    component: () => import('@/views/asup/avamar/AvamarReferenceList.vue'),
    meta: {
      action: '__special_manageReferenceList',
      pageTitle: 'Avamar Reference List',
      breadcrumb: [
        {
          text: 'Avamar Reference list',
          to: { name: 'reference-list.avamar' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/asup/reference-list/avamar/cross-check',
    name: 'reference-list.avamar.cross-check',
    component: () => import('@/views/asup/avamar/AvamarReferenceListCrossCheck.vue'),
    meta: {
      action: '__special_manageReferenceList',
      pageTitle: 'Cross Check',
      breadcrumb: [
        {
          text: 'Avamar Reference list',
          to: { name: 'reference-list.avamar' },
        },
        {
          text: 'Cross check',
          to: { name: 'reference-list.avamar.cross-check' },
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset',
    name: 'asset-list',
    component: () => import('@/views/assets/AssetList.vue'),
    meta: {
      action: 'Core.Assets',
    },
  },
  {
    path: '/asset/:id',
    name: 'asset-detail-page',
    component: () => import('@/views/assets/AssetDetail.vue'),
    meta: {
      pageTitle: 'Assets',
      action: 'Core.Assets',
      breadcrumb: [
        {
          text: 'Asset',
          to: { name: 'asset-list' },
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset/by-system-number/:systemnumber',
    name: 'asset-by-system-number-redirect',
    component: () => import('@/views/assets/AssetBySystemNumberRedirect.vue'),
    meta: {
      layout: 'full',
      action: 'Core.Assets',
    },
  },
  {
    path: '/asset/by-serial-number/:serialnumber',
    name: 'asset-by-serial-number-redirect',
    component: () => import('@/views/assets/AssetBySerialNumberRedirect.vue'),
    meta: {
      layout: 'full',
      action: 'Core.Assets',
    },
  },
  {
    path: '/asset/:id/asup',
    name: 'asset-to-asup-redirect',
    component: () => import('@/views/assets/AssetToAsupRedirect.vue'),
    meta: {
      layout: 'full',
      action: 'Core.Assets',
    },
  },
  {
    path: '/asup/datadomain',
    name: 'datadomain-list',
    component: () => import('@/views/asup/datadomain/DataDomainList.vue'),
    meta: {
      action: 'Autosupport.DataDomain',
    },
  },
  {
    path: '/asup/datadomain/:id',
    component: () => import('@/views/asup/datadomain/datadomain-detail/DataDomainDetail.vue'),
    children: [
      {
        path: '/',
        alias: ['general'],
        name: 'datadomain-detail.general',
        component: () => import('@/views/asup/datadomain/datadomain-detail/DataDomainDetailGeneral.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Autosupport.DataDomain',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'DataDomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'config',
        name: 'datadomain-detail.config',
        component: () => import('@/views/asup/datadomain/datadomain-detail/DataDomainDetailConfiguration.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Autosupport.DataDomain',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'DataDomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'Configuration',
              active: true,
            },
          ],
        },
      },
      {
        path: 'alerts',
        name: 'datadomain-detail.alerts',
        component: () => import('@/views/asup/datadomain/datadomain-detail/DataDomainAlert.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Autosupport.DataDomain',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'DataDomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'Alerts',
              active: true,
            },
          ],
        },
      },
      {
        path: 'capacity',
        name: 'datadomain-detail.capacity',
        component: () => import('@/views/asup/datadomain/datadomain-detail/DataDomainDetailCapacity.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Autosupport.DataDomain',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'DataDomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'Capacity',
              active: true,
            },
          ],
        },
      },
      {
        path: 'forecast',
        name: 'datadomain-detail.forecast',
        component: () => import('@/views/asup/datadomain/datadomain-detail/DataDomainDetailForecast.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Autosupport.DataDomain',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'DataDomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'Forecast',
              active: true,
            },
          ],
        },
      },
      {
        path: 'performance',
        name: 'datadomain-detail.performance',
        component: () => import('@/views/asup/datadomain/datadomain-detail/DataDomainDetailPerformance.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Autosupport.DataDomain',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'DataDomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'Performance',
              active: true,
            },
          ],
        },
      },
      {
        path: 'filereplication',
        name: 'datadomain-detail.filereplication',
        component: () => import('@/views/asup/datadomain/datadomain-detail/DataDomainDetailFileReplication.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Autosupport.DataDomain',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'DataDomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'File Replication',
              active: true,
            },
          ],
        },
      },
      {
        path: 'misc',
        name: 'datadomain-detail.misc',
        component: () => import('@/views/asup/datadomain/datadomain-detail/DataDomainDetailMisc.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Autosupport.DataDomain',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'DataDomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'Misc.',
              active: true,
            },
          ],
        },
      },
      {
        path: 'one-backup',
        name: 'datadomain-detail.apex',
        component: () => import('@/views/asup/datadomain/datadomain-detail/DataDomainDetailApex.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Autosupport.DataDomain',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'DataDomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'Misc.',
              active: true,
            },
          ],
        },
      },
      {
        path: 'contract',
        name: 'datadomain-detail.contract',
        component: () => import('@/views/asup/ContractInformation.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Autosupport.DataDomain',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'DataDomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'Contract & SLA',
              active: true,
            },
          ],
        },
      },
      {
        path: 'internal',
        name: 'datadomain-detail.internal',
        component: () => import('@/views/asup/InternalInformation.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Autosupport.DataDomain',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'DataDomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'Internals',
              active: true,
            },
          ],
        },
      },
      {
        path: 'raw',
        name: 'datadomain-detail.raw',
        component: () => import('@/views/asup/RawDataInformation.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Autosupport.DataDomain',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'DataDomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'Raw data',
              active: true,
            },
          ],
        },
      },
      {
        path: 'asup',
        name: 'datadomain-detail.asup',
        component: () => import('@/views/asup/RawAsupDataIn.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Autosupport.DataDomain',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'DataDomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'Asup data',
              active: true,
            },
          ],
        },
      },
      {
        path: 'tickets',
        name: 'datadomain-detail.tickets',
        component: () => import('@/views/asup/AsupTickets.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Core.Incidents',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Datadomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'Tickets',
              active: true,
            },
          ],
        },
      },
      {
        path: 'checkups',
        name: 'datadomain-detail.checkups',
        component: () => import('@/views/asup/DailyCheckInformation.vue'),
        meta: {
          pageTitle: 'DataDomain',
          action: 'Core.SystemCheckups',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Datadomain',
              to: { name: 'datadomain-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-detail.general' },
            },
            {
              text: 'ProActive Checkups',
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      pageTitle: 'DataDomain',
      action: 'Autosupport.DataDomain',
      contentClass: 'asup-detail',
      breadcrumb: [
        {
          text: 'DataDomain',
          to: { name: 'datadomain-list' },
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asup/dd-one-backup',
    name: 'datadomain-apex-list',
    component: () => import('@/views/asup/datadomain-apex/DataDomainApexList.vue'),
    meta: {
      action: 'Autosupport.DataDomain.Apex',
    },
  },
  {
    path: '/asup/dd-one-backup/:id',
    component: () => import('@/views/asup/datadomain-apex/datadomain-apex-detail/ApexDetail.vue'),
    children: [
      {
        path: '/',
        alias: ['general'],
        name: 'datadomain-apex-detail.general',
        component: () => import('@/views/asup/datadomain-apex/datadomain-apex-detail/ApexDetailGeneral.vue'),
        meta: {
          pageTitle: 'ONE Backup',
          action: 'Autosupport.DataDomain.Apex',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONE Backup',
              to: { name: 'datadomain-apex-list' },
            },
            {
              text: 'Details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'mtrees',
        name: 'datadomain-apex-detail.mtrees',
        component: () => import('@/views/asup/datadomain-apex/datadomain-apex-detail/ApexDetailMtrees.vue'),
        meta: {
          pageTitle: 'ONE Backup',
          action: 'Autosupport.DataDomain.Apex',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONE Backup',
              to: { name: 'datadomain-apex-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-apex-detail.general' },
            },
            {
              text: 'Mtree details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'mtrees-raw',
        name: 'datadomain-apex-detail.mtrees-raw',
        component: () => import('@/views/asup/datadomain-apex/datadomain-apex-detail/ApexDetailMtreeRawData.vue'),
        meta: {
          pageTitle: 'ONE Backup',
          action: 'Autosupport.DataDomain.Apex',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONE Backup',
              to: { name: 'datadomain-apex-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-apex-detail.general' },
            },
            {
              text: 'Mtree Raw data',
              active: true,
            },
          ],
        },
      },
      {
        path: 'contract',
        name: 'datadomain-apex-detail.contract',
        component: () => import('@/views/asup/ContractInformation.vue'),
        meta: {
          pageTitle: 'ONE Backup',
          action: 'Autosupport.DataDomain.Apex',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONE Backup',
              to: { name: 'datadomain-apex-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-apex-detail.general' },
            },
            {
              text: 'Contract & SLA',
              active: true,
            },
          ],
        },
      },
      {
        path: 'internal',
        name: 'datadomain-apex-detail.internal',
        component: () => import('@/views/asup/InternalInformation.vue'),
        meta: {
          pageTitle: 'ONE Backup',
          action: 'Autosupport.DataDomain.APEX',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONE Backup',
              to: { name: 'datadomain-apex-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-apex-detail.general' },
            },
            {
              text: 'Internals',
              active: true,
            },
          ],
        },
      },
      {
        path: 'tickets',
        name: 'datadomain-apex-detail.tickets',
        component: () => import('@/views/asup/AsupTickets.vue'),
        meta: {
          pageTitle: 'ONE Backup',
          action: 'Core.Incidents',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONE Backup',
              to: { name: 'datadomain-apex-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-apex-detail.general' },
            },
            {
              text: 'Tickets',
              active: true,
            },
          ],
        },
      },
      {
        path: 'checkups',
        name: 'datadomain-apex-detail.checkups',
        component: () => import('@/views/asup/DailyCheckInformation.vue'),
        meta: {
          pageTitle: 'ONE Backup',
          action: 'Core.SystemCheckups',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONE Backup',
              to: { name: 'datadomain-apex-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-apex-detail.general' },
            },
            {
              text: 'ProActive Checkups',
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      pageTitle: 'ONE Backup',
      action: 'Autosupport.DataDomain.Apex',
      contentClass: 'asup-detail',
      breadcrumb: [
        {
          text: 'ONE Backup',
          to: { name: 'datadomain-apex-list' },
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asup/agent',
    name: 'agent-list',
    component: () => import('@/views/asup/agent/AgentList.vue'),
    meta: {
      action: 'Autosupport.Agent',
    },
  },
  {
    path: '/asup/agent/:id',
    component: () => import('@/views/asup/agent/agent-detail/AgentDetail.vue'),
    children: [
      {
        path: '/',
        alias: ['general'],
        name: 'agent-detail.general',
        component: () => import('@/views/asup/agent/agent-detail/AgentDetailGeneral.vue'),
        meta: {
          pageTitle: 'ONEInsight Agent',
          action: 'Autosupport.Agent',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONEInsight Agent',
              to: { name: 'agent-list' },
            },
            {
              text: 'Details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'clients',
        name: 'agent-detail.clients',
        component: () => import('@/views/asup/agent/agent-detail/AgentDetailClients.vue'),
        meta: {
          pageTitle: 'ONEInsight Agent',
          action: 'Autosupport.Agent',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONEInsight Agent',
              to: { name: 'agent-list' },
            },
            {
              text: 'Details',
              to: { name: 'agent-detail.general' },
            },
            {
              text: 'Clients',
              active: true,
            },
          ],
        },
      },
      {
        path: 'log',
        name: 'agent-detail.log',
        component: () => import('@/views/asup/agent/agent-detail/AgentDetailLog.vue'),
        meta: {
          pageTitle: 'ONEInsight Agent',
          action: 'Autosupport.Agent',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONEInsight Agent',
              to: { name: 'agent-list' },
            },
            {
              text: 'Details',
              to: { name: 'agent-detail.general' },
            },
            {
              text: 'Log',
              active: true,
            },
          ],
        },
      },
      {
        path: 'raw',
        name: 'agent-detail.raw',
        component: () => import('@/views/asup/RawDataInformation.vue'),
        meta: {
          pageTitle: 'ONEInsight Agent',
          action: 'Autosupport.Agent',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONEInsight Agent',
              to: { name: 'agent-list' },
            },
            {
              text: 'Details',
              to: { name: 'agent-detail.general' },
            },
            {
              text: 'Raw data',
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      pageTitle: 'ONEInsight Agent',
      action: 'Autosupport.Agent',
      contentClass: 'asup-detail',
    },
  },
  {
    path: '/asup/agent-v2/:id',
    component: () => import('@/views/asup/agent/agent-detail-v2/AgentDetail.vue'),
    children: [
      {
        path: '/',
        alias: ['general'],
        name: 'agent-detail-v2.general',
        component: () => import('@/views/asup/agent/agent-detail-v2/AgentDetailGeneral.vue'),
        meta: {
          pageTitle: 'ONEInsight Agent v2',
          action: 'Autosupport.Agent',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONEInsight Agent',
              to: { name: 'agent-list' },
            },
            {
              text: 'Details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'clients',
        name: 'agent-detail-v2.clients',
        component: () => import('@/views/asup/agent/agent-detail-v2/AgentDetailClients.vue'),
        meta: {
          pageTitle: 'ONEInsight Agent v2',
          action: 'Autosupport.Agent',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONEInsight Agent',
              to: { name: 'agent-list' },
            },
            {
              text: 'Details',
              to: { name: 'agent-detail-v2.general' },
            },
            {
              text: 'Clients',
              active: true,
            },
          ],
        },
      },
      {
        path: 'clients/:clientId',
        name: 'agent-detail-v2.clients-details',
        component: () => import('@/views/asup/agent/agent-detail-v2/AgentDetailClientCollection.vue'),
        meta: {
          pageTitle: 'ONEInsight Agent v2',
          action: 'Autosupport.Agent',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONEInsight Agent',
              to: { name: 'agent-list' },
            },
            {
              text: 'Details',
              to: { name: 'agent-detail-v2.general' },
            },
            {
              text: 'Clients',
              to: { name: 'agent-detail-v2.clients' },
            },
            {
              text: 'Client Details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'raw',
        name: 'agent-detail-v2.raw',
        component: () => import('@/views/asup/RawDataInformation.vue'),
        meta: {
          pageTitle: 'ONEInsight Agent v2',
          action: 'Autosupport.Agent',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'ONEInsight Agent',
              to: { name: 'agent-list' },
            },
            {
              text: 'Details',
              to: { name: 'agent-detail-v2.general' },
            },
            {
              text: 'Raw data',
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      pageTitle: 'ONEInsight Agent',
      action: 'Autosupport.Agent',
      contentClass: 'asup-detail',
    },
  },
  {
    path: '/asup/networker',
    name: 'networker-list',
    component: () => import('@/views/asup/networker/NetWorkerList.vue'),
    meta: {
      action: 'Autosupport.NetWorker',
    },
  },
  {
    path: '/asup/networker/:id',
    component: () => import('@/views/asup/networker/networker-detail/NetWorkerDetail.vue'),
    children: [
      {
        path: '/',
        alias: ['general'],
        name: 'networker-detail.general',
        component: () => import('@/views/asup/networker/networker-detail/NetWorkerDetailGeneral.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'config',
        name: 'networker-detail.config',
        component: () => import('@/views/asup/networker/networker-detail/NetWorkerDetailConfiguration.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Configuration',
              active: true,
            },
          ],
        },
      },
      {
        path: 'bootstrap',
        name: 'networker-detail.bootstrap',
        component: () => import('@/views/asup/networker/networker-detail/NetWorkerBootstrap.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Bootstrap',
              active: true,
            },
          ],
        },
      },
      {
        path: 'client-matrix',
        name: 'networker-detail.client-matrix',
        component: () => import('@/views/asup/networker/networker-detail/NetWorkerClientMatrix.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Client Matrix',
              active: true,
            },
          ],
        },
      },
      {
        path: 'workflow-overview',
        name: 'networker-detail.workflow-overview',
        component: () => import('@/views/asup/networker/networker-detail/NetWorkerWorkflowOverview.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Workflow Overview',
              active: true,
            },
          ],
        },
      },
      {
        path: 'kpi',
        name: 'networker-detail.kpi',
        component: () => import('@/views/asup/networker/networker-detail/NetWorkerKpi.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'KPI',
              active: true,
            },
          ],
        },
      },
      {
        path: 'capacity',
        name: 'networker-detail.capacity',
        component: () => import('@/views/asup/networker/networker-detail/NetWorkerCapacity.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Capacity',
              active: true,
            },
          ],
        },
      },
      {
        path: 'log',
        name: 'networker-detail.log',
        component: () => import('@/views/asup/networker/networker-detail/NetWorkerLog.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Logs',
              active: true,
            },
          ],
        },
      },
      {
        path: 'workflow-duration',
        name: 'networker-detail.workflow-duration',
        component: () => import('@/views/asup/networker/networker-detail/NetWorkerWorkflowDuration.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Duration Gantt',
              active: true,
            },
          ],
        },
      },
      {
        path: 'contract',
        name: 'networker-detail.contract',
        component: () => import('@/views/asup/ContractInformation.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Contract & SLA',
              active: true,
            },
          ],
        },
      },
      {
        path: 'internal',
        name: 'networker-detail.internal',
        component: () => import('@/views/asup/InternalInformation.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Internals',
              active: true,
            },
          ],
        },
      },
      {
        path: 'raw',
        name: 'networker-detail.raw',
        component: () => import('@/views/asup/RawDataInformation.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Raw data',
              active: true,
            },
          ],
        },
      },
      {
        path: 'tickets',
        name: 'networker-detail.tickets',
        component: () => import('@/views/asup/AsupTickets.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Core.Incidents',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Tickets',
              active: true,
            },
          ],
        },
      },
      {
        path: 'checkups',
        name: 'networker-detail.checkups',
        component: () => import('@/views/asup/DailyCheckInformation.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Core.SystemCheckups',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'ProActive Checkups',
              active: true,
            },
          ],
        },
      },
      {
        path: 'client/:clientName',
        name: 'networker-detail.client-detail',
        component: () => import('@/views/asup/networker/networker-detail/NetWorkerClient.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Client',
              active: true,
            },
          ],
        },
      },
      {
        path: 'workflow/:workflowName',
        name: 'networker-detail.workflow-detail',
        component: () => import('@/views/asup/networker/networker-detail/NetWorkerWorkflow.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Workflow',
              active: true,
            },
          ],
        },
      },
      {
        path: 'workflow-job/:wfJobId',
        name: 'networker-detail.workflow-job-detail',
        component: () => import('@/views/asup/networker/networker-detail/NetWorkerWorkflowJob.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Workflow Job',
              active: true,
            },
          ],
        },
      },
      {
        path: 'workflow-job/:wfJobId/client/:client/saveset/:saveSetJobId',
        name: 'networker-detail.saveset-job-detail',
        component: () => import('@/views/asup/networker/networker-detail/NetWorkerWorkflowJobSaveSet.vue'),
        meta: {
          pageTitle: 'NetWorker',
          action: 'Autosupport.NetWorker',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'NetWorker',
              to: { name: 'networker-list' },
            },
            {
              text: 'Details',
              to: { name: 'networker-detail.general' },
            },
            {
              text: 'Workflow Job',
            },
            {
              text: 'SaveSet Job',
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      pageTitle: 'NetWorker',
      action: 'Autosupport.NetWorker',
      contentClass: 'asup-detail',
    },
  },
  {
    path: '/asup/veeam',
    name: 'veeam-list',
    component: () => import('@/views/asup/veeam/VeeamList.vue'),
    meta: {
      action: 'Autosupport.Veeam',
    },
  },
  {
    path: '/asup/veeam/:id',
    component: () => import('@/views/asup/veeam/veeam-detail/VeeamDetail.vue'),
    children: [
      {
        path: '/',
        alias: ['general'],
        name: 'veeam-detail.general',
        component: () => import('@/views/asup/veeam/veeam-detail/VeeamDetailGeneral.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Autosupport.Veeam',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'config',
        name: 'veeam-detail.config',
        component: () => import('@/views/asup/veeam/veeam-detail/VeeamDetailConfiguration.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Autosupport.Veeam',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              to: { name: 'veeam-detail.general' },
            },
            {
              text: 'Configuration',
              active: true,
            },
          ],
        },
      },
      {
        path: 'client-matrix',
        name: 'veeam-detail.client-matrix',
        component: () => import('@/views/asup/veeam/veeam-detail/VeeamClientMatrix.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Autosupport.Veeam',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              to: { name: 'veeam-detail.general' },
            },
            {
              text: 'Client Matrix',
              active: true,
            },
          ],
        },
      },
      {
        path: 'contract',
        name: 'veeam-detail.contract',
        component: () => import('@/views/asup/ContractInformation.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Autosupport.Veeam',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              to: { name: 'veeam-detail.general' },
            },
            {
              text: 'Contract & SLA',
              active: true,
            },
          ],
        },
      },
      {
        path: 'internal',
        name: 'veeam-detail.internal',
        component: () => import('@/views/asup/InternalInformation.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Autosupport.Veeam',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              to: { name: 'veeam-detail.general' },
            },
            {
              text: 'Internals',
              active: true,
            },
          ],
        },
      },
      {
        path: 'raw',
        name: 'veeam-detail.raw',
        component: () => import('@/views/asup/RawDataInformation.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Autosupport.Veeam',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              to: { name: 'veeam-detail.general' },
            },
            {
              text: 'Raw data',
              active: true,
            },
          ],
        },
      },
      {
        path: 'tickets',
        name: 'veeam-detail.tickets',
        component: () => import('@/views/asup/AsupTickets.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Core.Incidents',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              to: { name: 'veeam-detail.general' },
            },
            {
              text: 'Tickets',
              active: true,
            },
          ],
        },
      },
      {
        path: 'checkups',
        name: 'veeam-detail.checkups',
        component: () => import('@/views/asup/DailyCheckInformation.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Core.SystemCheckups',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              to: { name: 'veeam-detail.general' },
            },
            {
              text: 'ProActive Checkups',
              active: true,
            },
          ],
        },
      },
      {
        path: 'kpi',
        name: 'veeam-detail.kpi',
        component: () => import('@/views/asup/veeam/veeam-detail/VeeamKpi.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Autosupport.Veeam',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              to: { name: 'veeam-detail.general' },
            },
            {
              text: 'KPI',
              active: true,
            },
          ],
        },
      },
      {
        path: 'job-duration',
        name: 'veeam-detail.job-duration',
        component: () => import('@/views/asup/veeam/veeam-detail/VeeamJobDurationView.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Autosupport.Veeam',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              to: { name: 'veeam-detail.general' },
            },
            {
              text: 'Job Duration',
              active: true,
            },
          ],
        },
      },
      {
        path: 'client/:clientName',
        name: 'veeam-detail.client-detail',
        component: () => import('@/views/asup/veeam/veeam-detail/VeeamClient.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Autosupport.Veeam',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              to: { name: 'veeam-detail.general' },
            },
            {
              text: 'Client',
              active: true,
            },
          ],
        },
      },
      {
        path: 'job/:jobName',
        name: 'veeam-detail.job-detail',
        component: () => import('@/views/asup/veeam/veeam-detail/VeeamJob.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Autosupport.Veeam',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              to: { name: 'veeam-detail.general' },
            },
            {
              text: 'Job',
              active: true,
            },
          ],
        },
      },
      {
        path: 'job/:jobName/:jobSessionId',
        name: 'veeam-detail.job-session-detail',
        component: () => import('@/views/asup/veeam/veeam-detail/VeeamJobSessionDetail.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Autosupport.Veeam',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              to: { name: 'veeam-detail.general' },
            },
            {
              text: 'Job',
            },
            {
              text: 'Job Session',
              active: true,
            },
          ],
        },
      },
      {
        path: 'kpi',
        name: 'veeam-detail.kpi',
        component: () => import('@/views/asup/veeam/veeam-detail/VeeamKpi.vue'),
        meta: {
          pageTitle: 'Veeam',
          action: 'Autosupport.Veeam',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Veeam',
              to: { name: 'veeam-list' },
            },
            {
              text: 'Details',
              to: { name: 'veeam-detail.general' },
            },
            {
              text: 'KPI',
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      pageTitle: 'Veeam',
      action: 'Autosupport.Veeam',
      contentClass: 'asup-detail',
    },
  },
  {
    path: '/asup/ppdm',
    name: 'ppdm-list',
    component: () => import('@/views/asup/ppdm/PpdmList.vue'),
    meta: {
      action: 'Autosupport.Ppdm',
    },
  },
  {
    path: '/asup/ppdm/:id',
    component: () => import('@/views/asup/ppdm/ppdm-detail/PpdmDetail.vue'),
    children: [
      {
        path: '/',
        alias: ['general'],
        name: 'ppdm-detail.general',
        component: () => import('@/views/asup/ppdm/ppdm-detail/PpdmDetailGeneral.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Autosupport.Ppdm',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'config',
        name: 'ppdm-detail.config',
        component: () => import('@/views/asup/ppdm/ppdm-detail/PpdmDetailConfiguration.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Autosupport.Ppdm',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'Configuration',
              active: true,
            },
          ],
        },
      },
      {
        path: 'health-issues',
        name: 'ppdm-detail.health-issues',
        component: () => import('@/views/asup/ppdm/ppdm-detail/PpdmDetailHealthIssues.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Autosupport.Ppdm',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'Health Issues',
              active: true,
            },
          ],
        },
      },
      {
        path: 'alert',
        name: 'ppdm-detail.alert',
        component: () => import('@/views/asup/ppdm/ppdm-detail/PpdmDetailAlert.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Autosupport.Ppdm',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'Alerts',
              active: true,
            },
          ],
        },
      },
      {
        path: 'client-matrix',
        name: 'ppdm-detail.client-matrix',
        component: () => import('@/views/asup/ppdm/ppdm-detail/PpdmClientMatrix.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Autosupport.Ppdm',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'Client Matrix',
              active: true,
            },
          ],
        },
      },
      {
        path: 'kpi',
        name: 'ppdm-detail.kpi',
        component: () => import('@/views/asup/ppdm/ppdm-detail/PpdmKpi.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Autosupport.Ppdm',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'KPI',
              active: true,
            },
          ],
        },
      },
      {
        path: 'job-duration',
        name: 'ppdm-detail.job-duration',
        component: () => import('@/views/asup/ppdm/ppdm-detail/PpdmJobDurationView.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Autosupport.Ppdm',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'Job Duration',
              active: true,
            },
          ],
        },
      },
      {
        path: 'client/:clientName',
        name: 'ppdm-detail.client-detail',
        component: () => import('@/views/asup/ppdm/ppdm-detail/PpdmClient.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Autosupport.Ppdm',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'Client',
              active: true,
            },
          ],
        },
      },
      {
        path: 'protection-policy/:name',
        name: 'ppdm-detail.protection-policy-detail',
        component: () => import('@/views/asup/ppdm/ppdm-detail/PpdmProtectionPolicyView.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Autosupport.Ppdm',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'Protection Policy',
              active: true,
            },
          ],
        },
      },
      {
        path: 'protection-policy-job/:displayId',
        name: 'ppdm-detail.protection-policy-job-detail',
        component: () => import('@/views/asup/ppdm/ppdm-detail/PpdmProtectionPolicyJobView.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Autosupport.Ppdm',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'Protection Policy Job',
              active: true,
            },
          ],
        },
      },
      {
        path: 'contract',
        name: 'ppdm-detail.contract',
        component: () => import('@/views/asup/ContractInformation.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Autosupport.Ppdm',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'Contract & SLA',
              active: true,
            },
          ],
        },
      },
      {
        path: 'internal',
        name: 'ppdm-detail.internal',
        component: () => import('@/views/asup/InternalInformation.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Autosupport.Ppdm',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'Internals',
              active: true,
            },
          ],
        },
      },
      {
        path: 'tickets',
        name: 'ppdm-detail.tickets',
        component: () => import('@/views/asup/AsupTickets.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Core.Incidents',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'Tickets',
              active: true,
            },
          ],
        },
      },
      {
        path: 'checkups',
        name: 'ppdm-detail.checkups',
        component: () => import('@/views/asup/DailyCheckInformation.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Core.SystemCheckups',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'ProActive Checkups',
              active: true,
            },
          ],
        },
      },
      {
        path: 'raw',
        name: 'ppdm-detail.raw',
        component: () => import('@/views/asup/RawDataInformation.vue'),
        meta: {
          pageTitle: 'PowerProtect',
          action: 'Autosupport.Ppdm',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'ppdm-list' },
            },
            {
              text: 'Details',
              to: { name: 'ppdm-detail.general' },
            },
            {
              text: 'Raw data',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/asup/generic',
    name: 'generic-list',
    component: () => import('@/views/asup/generic/GenericList.vue'),
    meta: {
      action: 'Autosupport.Generic',
    },
  },
  {
    path: '/asup/generic/:id',
    component: () => import('@/views/asup/generic/generic-detail/GenericDetail.vue'),
    children: [
      {
        path: '/',
        alias: ['general'],
        name: 'generic-detail.general',
        component: () => import('@/views/asup/generic/generic-detail/GenericDetailGeneral.vue'),
        meta: {
          pageTitle: 'Generic',
          action: 'Autosupport.Generic',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Generic',
              to: { name: 'generic-list' },
            },
            {
              text: 'Details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'contract',
        name: 'generic-detail.contract',
        component: () => import('@/views/asup/ContractInformation.vue'),
        meta: {
          pageTitle: 'Generic',
          action: 'Autosupport.Generic',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Generic',
              to: { name: 'generic-list' },
            },
            {
              text: 'Details',
              to: { name: 'generic-detail.general' },
            },
            {
              text: 'Contract & SLA',
              active: true,
            },
          ],
        },
      },
      {
        path: 'internal',
        name: 'generic-detail.internal',
        component: () => import('@/views/asup/InternalInformation.vue'),
        meta: {
          pageTitle: 'Generic',
          action: 'Autosupport.Generic',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'PowerProtect',
              to: { name: 'generic-list' },
            },
            {
              text: 'Details',
              to: { name: 'generic-detail.general' },
            },
            {
              text: 'Internals',
              active: true,
            },
          ],
        },
      },
      {
        path: 'tickets',
        name: 'generic-detail.tickets',
        component: () => import('@/views/asup/AsupTickets.vue'),
        meta: {
          pageTitle: 'Generic',
          action: 'Core.Incidents',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Generic',
              to: { name: 'generic-list' },
            },
            {
              text: 'Details',
              to: { name: 'generic-detail.general' },
            },
            {
              text: 'Tickets',
              active: true,
            },
          ],
        },
      },
      {
        path: 'checkups',
        name: 'generic-detail.checkups',
        component: () => import('@/views/asup/DailyCheckInformation.vue'),
        meta: {
          pageTitle: 'Generic',
          action: 'Core.SystemCheckups',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Generic',
              to: { name: 'generic-list' },
            },
            {
              text: 'Details',
              to: { name: 'generic-detail.general' },
            },
            {
              text: 'ProActive Checkups',
              active: true,
            },
          ],
        },
      },
      {
        path: 'raw',
        name: 'generic-detail.raw',
        component: () => import('@/views/asup/RawDataInformation.vue'),
        meta: {
          pageTitle: 'Generic',
          action: 'Autosupport.Generic',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Generic',
              to: { name: 'generic-list' },
            },
            {
              text: 'Details',
              to: { name: 'generic-detail.general' },
            },
            {
              text: 'Raw data',
              active: true,
            },
          ],
        },
      },
      {
        path: 'asup',
        name: 'generic-detail.asup',
        component: () => import('@/views/asup/RawAsupDataIn.vue'),
        meta: {
          pageTitle: 'Generic',
          action: 'Autosupport.Generic',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Generic',
              to: { name: 'generic-list' },
            },
            {
              text: 'Details',
              to: { name: 'datadomain-generic.general' },
            },
            {
              text: 'Asup data',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/asup/avamar',
    name: 'avamar-list',
    component: () => import('@/views/asup/avamar/AvamarList.vue'),
    meta: {
      action: 'Autosupport.Avamar',
    },
  },
  {
    path: '/asup/avamar/:id',
    component: () => import('@/views/asup/avamar/avamar-detail/AvamarDetail.vue'),
    children: [
      {
        path: '/',
        alias: ['general'],
        name: 'avamar-detail.general',
        component: () => import('@/views/asup/avamar/avamar-detail/AvamarDetailGeneral.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Autosupport.Avamar',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'config',
        name: 'avamar-detail.config',
        component: () => import('@/views/asup/avamar/avamar-detail/AvamarDetailConfiguration.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Autosupport.Avamar',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              to: { name: 'avamar-detail.general' },
            },
            {
              text: 'Configuration',
              active: true,
            },
          ],
        },
      },
      {
        path: 'events',
        name: 'avamar-detail.events',
        component: () => import('@/views/asup/avamar/avamar-detail/AvamarDetailEvents.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Autosupport.Avamar',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              to: { name: 'avamar-detail.general' },
            },
            {
              text: 'Events',
              active: true,
            },
          ],
        },
      },
      {
        path: 'capacity',
        name: 'avamar-detail.capacity',
        component: () => import('@/views/asup/avamar/avamar-detail/AvamarDetailCapacity.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Autosupport.Avamar',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              to: { name: 'avamar-detail.general' },
            },
            {
              text: 'Capacity',
              active: true,
            },
          ],
        },
      },
      {
        path: 'client-matrix',
        name: 'avamar-detail.client-matrix',
        component: () => import('@/views/asup/avamar/avamar-detail/AvamarClientMatrix.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Autosupport.Avamar',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              to: { name: 'avamar-detail.general' },
            },
            {
              text: 'Client Matrix',
              active: true,
            },
          ],
        },
      },
      {
        path: 'contract',
        name: 'avamar-detail.contract',
        component: () => import('@/views/asup/ContractInformation.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Autosupport.Avamar',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              to: { name: 'avamar-detail.general' },
            },
            {
              text: 'Contract & SLA',
              active: true,
            },
          ],
        },
      },
      {
        path: 'internal',
        name: 'avamar-detail.internal',
        component: () => import('@/views/asup/InternalInformation.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Autosupport.Avamar',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              to: { name: 'avamar-detail.general' },
            },
            {
              text: 'Internals',
              active: true,
            },
          ],
        },
      },
      {
        path: 'raw',
        name: 'avamar-detail.raw',
        component: () => import('@/views/asup/RawDataInformation.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Autosupport.Avamar',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              to: { name: 'avamar-detail.general' },
            },
            {
              text: 'Raw data',
              active: true,
            },
          ],
        },
      },
      {
        path: 'tickets',
        name: 'avamar-detail.tickets',
        component: () => import('@/views/asup/AsupTickets.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Core.Incidents',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              to: { name: 'avamar-detail.general' },
            },
            {
              text: 'Tickets',
              active: true,
            },
          ],
        },
      },
      {
        path: 'checkups',
        name: 'avamar-detail.checkups',
        component: () => import('@/views/asup/DailyCheckInformation.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Core.SystemCheckups',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              to: { name: 'avamar-detail.general' },
            },
            {
              text: 'ProActive Checkups',
              active: true,
            },
          ],
        },
      },
      {
        path: 'client/:clientName',
        name: 'avamar-detail.client-detail',
        component: () => import('@/views/asup/avamar/avamar-detail/AvamarClient.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Autosupport.Avamar',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              to: { name: 'avamar-detail.general' },
            },
            {
              text: 'Client',
              active: true,
            },
          ],
        },
      },
      {
        path: 'policy/:jobGroup',
        name: 'avamar-detail.group-detail',
        component: () => import('@/views/asup/avamar/avamar-detail/AvamarPolicy.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Autosupport.Avamar',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              to: { name: 'avamar-detail.general' },
            },
            {
              text: 'Policy',
              active: true,
            },
          ],
        },
      },
      {
        path: 'kpi',
        name: 'avamar-detail.kpi',
        component: () => import('@/views/asup/avamar/avamar-detail/AvamarKpi.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Autosupport.Avamar',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              to: { name: 'avamar-detail.general' },
            },
            {
              text: 'KPI',
              active: true,
            },
          ],
        },
      },
      {
        path: 'job-duration',
        name: 'avamar-detail.job-duration',
        component: () => import('@/views/asup/avamar/avamar-detail/AvamarJobDurationView.vue'),
        meta: {
          pageTitle: 'Avamar',
          action: 'Autosupport.Avamar',
          contentClass: 'asup-detail',
          breadcrumb: [
            {
              text: 'Avamar',
              to: { name: 'avamar-list' },
            },
            {
              text: 'Details',
              to: { name: 'avamar-detail.general' },
            },
            {
              text: 'Job Duration',
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      pageTitle: 'Avamar',
      action: 'Autosupport.Avamar',
      contentClass: 'asup-detail',
    },
  },
  {
    path: '/profile',
    name: 'account-setting',
    component: () => import('@/views/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/monitor',
    name: 'monitor-app',
    component: () => import('@/views/monitor/monitor.vue'),
    meta: {
      pageTitle: 'Monitor',
      action: 'Watchdog.Monitors',
      breadcrumb: [
        {
          text: 'Monitor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ticket',
    name: 'incident-list',
    component: () => import('@/views/incident/incidentList.vue'),
    meta: {
      pageTitle: 'Tickets',
      action: 'Core.Incidents',
      breadcrumb: [
        {
          text: 'Tickets',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ticket/:id',
    name: 'incident-detail',
    component: () => import('@/views/incident/incidentDetail.vue'),
    meta: {
      pageTitle: 'Ticket Detail',
      action: 'Core.Incidents',
      breadcrumb: [
        {
          text: 'Tickets',
          to: { name: 'incident-list' },
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset/:id/ticket/create',
    name: 'asset-create-ticket',
    component: () => import('@/views/incident/incidentCreate.vue'),
    meta: {
      pageTitle: 'Create ticket',
      action: 'Core.Incidents.Create',
      breadcrumb: [
        {
          text: 'Asset',
          to: { name: 'asset-list' },
        },
        {
          text: 'Details',
        },
        {
          text: 'Create ticket',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manage/tenant/:id/manage',
    name: 'manage:tenant-manage',
    component: () => import('@/views/shared/tenant/ManageOuView.vue'),
    meta: {
      pageTitle: 'Organizations',
      breadcrumb: [
        {
          text: 'Organizations',
          to: { name: 'host:manage:tenant-list' },
        },
        {
          text: 'Detail',
          to: { name: 'host:manage:tenant-detail' },
        },
        {
          text: 'Manage',
          active: true,
        },
      ],
      action: 'Core.OrganizationUnits',
    },
  },
  {
    path: '/asup/datadomain/:id/chart/data-usage-percentage',
    component: () => import('@/views/asup/datadomain/datadomain-detail/charts/DataUsagePercentageChartView.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true,
    },
  },
  {
    path: '/asup/datadomain/:id/chart/daily-capacity-pre-post-dedup',
    component: () => import('@/views/asup/datadomain/datadomain-detail/charts/DailyCapacityPreAndPostDedupChartView.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true,
    },
  },
  {
    path: '/asup/datadomain/:id/chart/weekly-capacity-pre-post-dedup',
    component: () => import('@/views/asup/datadomain/datadomain-detail/charts/WeeklyCapacityPreAndPostDedupChartView.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true,
    },
  },
  {
    path: '/asup/datadomain/:id/chart/data-optimization-factor',
    component: () => import('@/views/asup/datadomain/datadomain-detail/charts/DataOptimizationFactorOverTimeChartView.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true,
    },
  },
  {
    path: '/asup/datadomain/:id/chart/data-usage-pre-post-optimization',
    component: () => import('@/views/asup/datadomain/datadomain-detail/charts/DataUsagePreAndPostOptimizationChartView.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true,
    },
  },
  {
    path: '/asup/datadomain/:id/chart/physical-capacity',
    component: () => import('@/views/asup/datadomain/datadomain-detail/charts/PhysicalCapacityChartView.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true,
    },
  },
  {
    path: '/report/asup',
    name: 'asupReport',
    component: () => import('@/views/reporting/AsupGroupListView.vue'),
    meta: {
      pageTitle: 'Autosupport Email Report',
      action: 'Core.Reporting.AsupReport',
    },
  },
  {
    path: '/asup/networker/:id/client-matrix-standalone',
    component: () => import('@/views/asup/networker/networker-detail/NetWorkerClientMatrixServerSide.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true,
    },
  },
  {
    name: 'downloads',
    path: '/downloads',
    component: () => import('@/views/downloads/DownloadView.vue'),
    meta: {
      pageTitle: 'Download Area',
      action: '',
    },
  },
  {
    path: '/asup/avamar/:id/client-matrix-standalone',
    component: () => import('@/views/asup/avamar/avamar-detail/AvamarClientMatrixServerSide.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true,
    },
  },
  {
    path: '/asup/veeam/:id/client-matrix-standalone',
    component: () => import('@/views/asup/veeam/veeam-detail/VeeamClientMatrixServerSide.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true,
    },
  },
  {
    path: '/asup/ppdm/:id/client-matrix-standalone',
    component: () => import('@/views/asup/ppdm/ppdm-detail/PpdmClientMatrixServerSide.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true,
    },
  },
]

/* eslint-disable indent */
import Vue from 'vue'
import { abilitiesPlugin } from '@casl/vue'
import store from '@/store'
import ability from './ability'

Vue.use(abilitiesPlugin, ability)

function applyCalculatedPermissions(grantedPermissions) {
  // Add Autosupport permission if any Autosupport permission has been found (autosupport permissions are built like: "Autosupport.DataDomain", "Autosupport.NetWorker", ...)
  if (grantedPermissions.find(x => x.startsWith('Autosupport'))) {
    grantedPermissions.push('Autosupport')
  }

  if (grantedPermissions.some(x => x === 'Core.Users'
    || x === 'Core.Roles'
    || x === 'Core.OrganizationUnits')) {
    grantedPermissions.push('ManageOrganization')
  }
}

function getGrantedAbilities() {
  // get permissions from auth store
  const grantedPermissions = store.getters['auth/grantedPermissions']
  const isHost = store.getters['auth/isHost']
  const multiTenancyPrefix = isHost ? '__host__' : '__tenant__'

  // map permissions (flattened list) to ACL ability model
  const abilities = grantedPermissions.map(x => ({ action: x }))

  applyCalculatedPermissions(grantedPermissions)

  // add same permissions but with MultiTenancySide prefix for advanced usage (see navigation item protection)
  abilities.push(...grantedPermissions.map(x => ({ action: multiTenancyPrefix + x })))
  abilities.push({ action: multiTenancyPrefix })

  // add special roles as permissions
  const isSystemAdmin = store.getters['auth/isSystemAdmin']
  const isTenantAdmin = store.getters['auth/isTenantAdmin']
  if (isSystemAdmin) {
    abilities.push({ action: '__role__systemadmin' })
  }
  if (isTenantAdmin) {
    abilities.push({ action: '__role__tenantadmin' })
  }

  // add special permissions (mix of many permissions or roles)
  if (isTenantAdmin || grantedPermissions.some(x => x === 'Autosupport.Avamar.ManageReferenceList')) { // Tenant Admins or HOST users with special role can manage reference lists
    abilities.push({ action: '__special_manageReferenceList' })
  }

  if (isTenantAdmin || isSystemAdmin || isHost) {
    abilities.push({ action: '__special_managePermission' })
  }

  // Add empty action ability, so that everything is allowed with no action defined, otherwise we would need to define an action for every route and menu item
  abilities.push({ action: '' })

  return abilities
}

store.subscribe(mutation => {
  // eslint-disable-next-line default-case
  switch (mutation.type) {
    case 'auth/SET_USERPROFILE':
      {
        const abilities = getGrantedAbilities()
        ability.update(abilities)
        localStorage.setItem('acl', JSON.stringify(abilities))

        Vue.$log.debug('Granted abilities', abilities)

        break
      }
    case 'LOGOUT_REQUEST_SUCCESS':
      ability.update([])
      localStorage.removeItem('acl')
      break
  }
})

import Oidc from 'oidc-client'
import appConfig from '@/config'

Oidc.Log.logger = console
Oidc.Log.level = Oidc.Log.ERROR // (process.env.NODE_ENV === 'production') ? Oidc.Log.ERROR : Oidc.Log.DEBUG

let instance

// OIDC Client
// eslint-disable-next-line import/prefer-default-export
export const getOidcClient = () => {
  if (instance) {
    return instance
  }

  instance = new Oidc.UserManager({
    authority: appConfig.oidcAuthority,
    // User localStorage to save user to make it working opening new tabs of the UI without redirecting to Idsrv.
    // If we find any issues here, we can revert it to sessionStorage and try to redirect user back to the initial opened URL after signin to Idsrv.
    userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
    client_id: appConfig.oidcClientId,
    redirect_uri: `${window.location.origin}/oidc-callback`,
    response_type: 'code',
    scope: appConfig.oidcScope,
    post_logout_redirect_uri: `${window.location.origin}/oidc-logout`,
    silent_redirect_uri: `${window.location.origin}/oidc-silent-renew`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: false,
    mergeClaims: false,
  })

  // eslint-disable-next-line func-names
  instance.impersonate = function (userId) {
    // Build Impersonation Uri
    const callbackUri = `${window.location.origin}/impersonate-callback`
    const impersonateUri = `${appConfig.oidcAuthority}/account/impersonate?client_id=${appConfig.oidcClientId}&tu=${encodeURIComponent(userId)}&al=true&redirect_uri=${encodeURIComponent(callbackUri)}`

    // Redirect to impersonation Uri (Idsrv)
    window.location = impersonateUri
  }

  // eslint-disable-next-line func-names
  instance.cancelImpersonation = function () {
    // Build Impersonation Uri
    const callbackUri = `${window.location.origin}/impersonate-callback`
    const impersonateUri = `${appConfig.oidcAuthority}/account/impersonate?client_id=${appConfig.oidcClientId}&redirect_uri=${encodeURIComponent(callbackUri)}&action=cancel`

    // Redirect to impersonation Uri (Idsrv)
    window.location = impersonateUri
  }

  // eslint-disable-next-line func-names
  instance.redirectAfterImpersonation = function () {
    // Redirect to home after impersonation
    window.location = window.location.origin
  }

  return instance
}

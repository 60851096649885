export default [
  {
    path: '/tenant/manage/tenant',
    name: 'tenant:manage:tenant-detail',
    component: () => import('@/views/tenant/manage/tenant/TenantDetail.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Organization',
          active: true,
        },
      ],
      action: '__tenant__ManageOrganization',
    },
  },
  {
    path: '/manage/tenant/user/:userid',
    name: 'tenant:manage:tenant-user-detail',
    component: () => import('@/views/host/manage/user/UserDetail.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Organization',
          to: { name: 'tenant:manage:tenant-detail' },
        },
        {
          text: 'User',
          active: true,
        },
      ],
      action: 'Core.Users',
    },
  },
]

<template>
  <div />
</template>

<script>

import { getOidcClient } from '@/auth/oidc/oidc-client'

export default {
  mounted() {
    const self = this
    const oidcClient = getOidcClient()

    // eslint-disable-next-line no-unused-vars
    /* oidcClient.events.addAccessTokenExpiring(() => {
      debugger
      oidcClient.signinSilent()
        .then(() => {
          this.silentSigninFailedCount = 0
        })
        // eslint-disable-next-line no-unused-vars
        .catch(e => {
          this.silentSigninFailedCount += 1
          oidcClient.getUser()
        })
    }) */

    oidcClient.events.addSilentRenewError(e => {
      this.$log.debug('Undefined error at renewing authentication.', e)

      self.$swal.fire({
        title: 'Login expired',
        text: 'Your login has been expired. You need to reauthenticate to continue your work.',
        icon: 'info',
        confirmButtonText: 'Login',
      }).then(result => {
        if (result.isConfirmed) {
          self.$store.dispatch('auth/signin', this.$route.fullPath)
        }
      })
    })
  },
}

</script>

import appConfig from '@/config'

export default [
  {
    path: '/host/manage/host',
    name: 'host:manage:host-detail',
    component: () => import('@/views/host/manage/host/HostDetail.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Host',
          active: true,
        },
      ],
      action: '__role__systemadmin',
    },
  },
  {
    path: '/host/manage/tenant',
    name: 'host:manage:tenant-list',
    component: () => import('@/views/host/manage/tenant/TenantList.vue'),
    meta: {
      pageTitle: 'Organizations',
      breadcrumb: [
        {
          text: 'Organizations',
          active: true,
        },
      ],
      action: '__host__Core.Tenants',
    },
  },
  {
    path: '/host/manage/tenant/find/:id',
    name: 'host:tenant-find-by-id',
    component: () => import('@/views/tenant/TenantFindByIdRedirect.vue'),
    meta: {
      layout: 'full',
      action: '__host__Core.Tenants',
    },
  },
  {
    path: '/host/manage/tenant/:id',
    name: 'host:manage:tenant-detail',
    component: () => import('@/views/host/manage/tenant/TenantDetail.vue'),
    meta: {
      pageTitle: 'Organizations',
      breadcrumb: [
        {
          text: 'Organizations',
          to: { name: 'host:manage:tenant-list' },
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
      action: '__host__Core.Tenants',
    },
  },
  {
    path: '/host/manage/tenant/:id/user/:userid',
    name: 'host:manage:tenant-user-detail',
    component: () => import('@/views/host/manage/user/UserDetail.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Organizations',
          to: { name: 'host:manage:tenant-list' },
        },
        {
          text: 'Detail',
          to: { name: 'host:manage:tenant-detail' },
        },
        {
          text: 'User',
          active: true,
        },
      ],
      action: '__host__Core.Users',
    },
  },
  {
    path: '/host/manage/user',
    name: 'host:manage:user-list',
    component: () => import('@/views/host/manage/user/UserList.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
      action: '__host__Core.Tenants',
    },
  },
  {
    path: '/host/manage/message',
    name: 'host:manage:message',
    component: () => import('@/views/message/MessageList.vue'),
    meta: {
      action: 'Message.Messages',
      pageTitle: 'Messages',
      breadcrumb: [
        {
          text: 'Messages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/host/manage/message/:id',
    name: 'host:manage:message-detail',
    component: () => import('@/views/message/MessageDetail.vue'),
    meta: {
      pageTitle: 'Message details',
      breadcrumb: [
        {
          text: 'Messages',
          to: { name: 'host:manage:message' },
        },
        {
          text: 'Details',
          active: true,
        },
      ],
      action: 'Message.Messages',
    },
  },
  {
    path: '/report/rms',
    name: 'rmsReport',
    component: () => import('@/views/reporting/RmsReportGeneratorView.vue'),
    meta: {
      pageTitle: 'RMS Report',
      action: '__host__Core.Reporting.Rms',
    },
  },
  {
    path: '/external/zammad/:id',
    name: 'zammad:ticket',
    beforeEnter: to => {
      const baseUrl = appConfig.zammadTicketUrl
      const redirectUrl = baseUrl
        .replace('%TICKETID%', to.params.id)

      window.location.href = redirectUrl

      return redirectUrl
    },
  },
]

import Vue from 'vue'
import moment from '@/libs/moment'

const prettyBytes = require('@/utils/pretty-bytes')

Vue.filter('formatDate', _value => {
  if (_value) {
    const date = moment.utc(_value)
    return date.format('L')
  }

  return null
})

Vue.filter('formatDateTime', _value => {
  if (_value) {
    const dateTime = moment.utc(_value)
    return dateTime.format('L LT')
  }

  return null
})

Vue.filter('formatDateTimeTz', (_value, _timezone) => {
  if (_value) {
    if (_timezone) {
      return moment.utc(_value).tz(_timezone).format('L LT')
    }
    return moment.utc(_value).format('L LT')
  }

  return null
})

Vue.filter('formatClrTimeSpan', _value => {
  if (_value && _value) {
    const duration = moment.duration(_value)
    return duration.humanize()
  }

  return null
})

Vue.filter('formatTimeSpanFromNow', _value => {
  if (_value) {
    const dateTime = moment(_value)
    const now = moment()
    const diff = now.diff(dateTime)
    const duration = moment.duration(diff)
    return duration.locale('en').humanize()
  }

  return null
})

Vue.filter('agentClientTypeDisplayName', _value => {
  switch (_value) {
    case 0: return 'Dummy'
    case 1: return 'Agent'
    case 2: return 'NetWorker'
    case 3: return 'Avamar'
    case 4: return 'DataDomain'
    case 5: return 'Veeam'
    case 6: return 'PowerProtect'
    case 7: return 'Cyber Recovery'
    case 8: return 'Brocade'
    case 9: return 'Dell Server / IDrac'
    case 10: return 'PowerVault ME4'
    default: return 'Undefined'
  }
})

Vue.filter('avatarText', (_value, _maxCharCount) => {
  if (!_value) return ''
  const maxCharCount = _maxCharCount || 3
  const nameArray = _value.split(' ')
  return nameArray.slice(0, maxCharCount).map(word => word.charAt(0).toUpperCase()).join('')
})

Vue.filter('prettyBytes', (_value, _options) => {
  if (!_value) return ''
  return prettyBytes(_value, _options)
})

Vue.filter('avamarJobTypeDisplayName', _value => {
  switch (_value) {
    case 0: return 'Backup'
    case 1: return 'Replication'
    default: return 'Undefined'
  }
})

Vue.filter('veeamJobTypeDisplayName', (_value, _platform) => {
  switch (_value) {
    case 0:
      if (_platform === 0) {
        return 'VMware Backup'
      }
      if (_platform === 1) {
        return 'Hyper-V Backup'
      }

      return 'VM'
    case 1: return 'Replication Job'
    case 2: return 'VM Copy Job'
    case 3: return 'SureBackup'
    case 24: return 'File to Tape Job'
    case 28: return 'Tape Job'
    case 51: return 'Backup Copy Job'
    case 52: return 'SQL Transaction Log Backup'
    case 54: return 'Oracle Log'
    case 63:
    case 65:
      return 'Backup Copy'
    case 68: return 'Sql Transaction Log Backup Copy Job'
    case 4000: return 'Linux Agent Backup'
    case 4030: return 'Oracle RMAN Backup'
    case 4031: return 'SAP Backup'
    case 4037: return 'SAP Backup Copy'
    case 4040: return 'SAP Oracle Backup'
    case 12000: return 'Agent Child Job'
    case 12003: return 'Agent Parent Job'
    case 13000: return 'File Share Backup Job'
    case 13003: return 'File Share Backup Copy Job'
    default: return _value
  }
})

Vue.filter('veeamRepositoryTypeDisplayName', _value => {
  switch (_value) {
    case 0: return 'Windows'
    case 1: return 'Linux'
    case 2: return 'CIFS'
    case 3: return 'DataDomain'
    case 4: return 'Cloud'
    case 10: return 'Scale Out'
    case 14: return 'S3-compatible'
    case 18: return 'Quantum DXi'
    case 35: return 'Hardened'
    default: return _value
  }
})

Vue.filter('veeamJobClassificationDisplayName', _value => {
  switch (_value) {
    case 0: return 'Backup'
    case 1: return 'Backup Copy'
    default: return _value
  }
})

Vue.filter('veeamJobCompressionLevelDisplayName', _value => {
  if (_value === null) return '-'
  switch (_value) {
    case 9: return 'Extreme'
    case 6: return 'High'
    case 5: return 'Optimal (recommended)'
    case 4: return 'Dedup-friendly'
    case 0: return 'None'
    default: return ''
  }
})

Vue.filter('jobStatusDisplayName', _value => {
  switch (_value) {
    case 0: return 'Success'
    case 10: return 'Active'
    case 20: return 'Warning'
    case 30: return 'Failed'
    default: return 'Undefined'
  }
})

Vue.filter('incidentStatusDisplayName', _value => {
  switch (_value) {
    case 1: return 'New'
    case 2: return 'Open'
    case 3: return 'Wait for reminder'
    case 4: return 'Closed'
    case 7: return 'Wait for close'
    default: return ''
  }
})

Vue.filter('renameRoleName', _value => {
  if (_value === 'TenantAdmin') return 'Organization Administrator'
  if (_value === 'SystemAdmin') return 'System Administrator'

  return _value
})

Vue.filter('systemTypeDisplayName', (_value, multiple) => {
  if (!_value) return ''

  let result = ''

  switch (_value) {
    case 1000: result = 'DataDomain'; break
    case 1001: result = 'NetApp'; break
    case 1002: result = 'VNX'; break
    case 1003: result = 'Unity'; break
    case 1004: result = 'Quantum'; break
    case 1005: result = 'NetWorker (old)'; break
    case 1006: result = 'Veeam'; break
    case 1008: result = 'NetWorker'; break
    case 1009: result = 'ONEInsight Agent'; break
    case 1010: result = 'Avamar'; break
    case 1011: result = 'Veeam'; break
    case 1012: result = 'PowerProtect'; break
    case 1096: result = 'FastLTA'; break
    case 1014: result = 'Brocade'; break
    case 1013: result = 'CyberRecovery'; break
    case 1015: result = 'iDrac'; break
    case 21000: result = 'ONE Backup'; break
    case 21001: result = 'Generic'; break
    default: result = _value
  }

  if (multiple) {
    return `${result}´s`
  }

  return result
})

Vue.filter('assetBundleDisplayName', asset => {
  if (!asset) return ''

  const { isParentBundle, bundleType } = asset

  if (bundleType === 100000000) {
    return 'Single'
  }
  if (isParentBundle === true) {
    return 'Is Parent'
  }

  return 'Is Child'
})

Vue.filter('medialineBusinessUnitName', _value => {
  if (!_value) return '-'

  switch (_value) {
    case 100000000: return 'mentIQ GmbH'
    case 100000001: return 'ITK Communications GmbH'
    case 100000002: return 'interface systems GmbH'
    case 100000003: return 'GID GmbH'
    case 100000004: return 'CYBERDYNE Informationstechnologie GmbH'
    case 100000005: return 'Medialine AG'
    case 100000006: return 'Medialine Österreich'
    default: return _value
  }
})

Vue.filter('agentProcessorMode', processorMode => {
  switch (processorMode) {
    case 1: return 'E-Mail'
    case 2: return 'Folder'
    default: return 'None'
  }
})

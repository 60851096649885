// support object and array GET params
// use it with Axios by using this function for paramsSerializer in request config.
// see https://github.com/axios/axios/issues/604#issuecomment-420135579
export function parseGetParams(params) {
  const keys = Object.keys(params)
  let options = ''

  keys.forEach(key => {
    if (params[key] === null) {
      return
    }

    const isParamTypeObject = typeof params[key] === 'object'
    const isParamTypeArray = isParamTypeObject && (params[key].length >= 0)

    if (!isParamTypeObject) {
      options += `${key}=${params[key]}&`
    }

    if (isParamTypeObject && isParamTypeArray) {
      let index = 0
      params[key].forEach(element => {
        options += `${key}[${index}]=${element}&`
        index += 1
      })
    }
  })

  return options ? options.slice(0, -1) : options
}

export function buildAxiosRequestConfig(_config, action) {
  // eslint-disable-next-line no-param-reassign
  _config = _config || {}

  const config = {
    disableTenantFilter: _config.disableTenantFilter || false,
    impersonateAsTenant: _config.impersonateAsTenant || null,
  }

  const axiosRequestConfig = {}

  if (config.disableTenantFilter === true && config.impersonateAsTenant !== null) {
    throw new Error('Disabling tenant filter and impersonating as tenant can not be set at the same time')
  }

  axiosRequestConfig.headers = _config.headers || {}
  if (config.impersonateAsTenant !== null) {
    // eslint-disable-next-line no-underscore-dangle
    axiosRequestConfig.headers.__imp = config.impersonateAsTenant
  } else if (config.disableTenantFilter === true) {
    // eslint-disable-next-line no-underscore-dangle
    axiosRequestConfig.headers.__imp = '-1'
  }

  if (action && typeof action === 'function') {
    action(axiosRequestConfig)
  }

  return axiosRequestConfig
}

export default {
  buildAxiosRequestConfig,
  parseGetParams,
}

// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig } from './serviceManager'

export default {
  async getAllListAsync(_options, config) {
    const options = {
      includeDefaultOu: _options?.includeDefaultOu || true,
    }

    const pageSize = 1000
    let page = 0
    let totalCount = 0

    const result = []

    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get(`/core/asset/?IncludeDefaultOu=${options.includeDefaultOu}&MaxResultCount=${pageSize}&SkipCount=${page * pageSize}`, buildAxiosRequestConfig(config))
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getAsync(id, config) {
    const result = await axios.get(`/core/asset/${id}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async getBySystemNumberAsync(systemNumber, config) {
    const result = await axios.get(`/core/asset/by-system-number/${systemNumber}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async getBySerialNumberAsync(serialNumber, config) {
    const result = await axios.get(`/core/asset/by-serial-number/${serialNumber}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async updateAsync(id, data, config) {
    const result = await axios.put(`/core/asset/${id}`, data, buildAxiosRequestConfig(config))
    return result.data
  },

  async updateSupportInfoAsync(id, data, config) {
    const result = await axios.put(`/core/asset/${id}/support-info`, data, buildAxiosRequestConfig(config))
    return result.data
  },

  async getAllSlasAsync(config) {
    const result = await axios.get('/core/sla', buildAxiosRequestConfig(config))
    return result.data.items
  },

  async getSlasOfAssetAsync(asset, config) {
    const allSlas = await this.getAllSlasAsync(config)
    if (!asset.slaIds || !allSlas) {
      return []
    }

    const result = []
    for (let i = 0; i < asset.slaIds.length; i += 1) {
      for (let j = 0; j < allSlas.length; j += 1) {
        if (asset.slaIds[i] === allSlas[j].id) {
          result.push(allSlas[j])
        }
      }
    }

    return result.sort((a, b) => (a.name > b.name ? 1 : -1))
  },

  async updateOuAsync(assetId, ouId, config) {
    if (ouId === null) {
      // eslint-disable-next-line no-return-await
      return await axios.put(`/core/asset/${assetId}/ou`, {}, buildAxiosRequestConfig(config))
    }

    // eslint-disable-next-line no-return-await
    return await axios.put(`/core/asset/${assetId}/ou?ouId=${ouId}`, {}, buildAxiosRequestConfig(config))
  },

  async triggerAllAssetAsupMatcher(config) {
    const result = await axios.post('/core/asset/trigger/asset-asup-matcher', {}, buildAxiosRequestConfig(config))
    return result.data
  },

  async triggerSingleAssetAsupMatcher(assetId, config) {
    const result = await axios.post(`/core/asset/trigger/asset-asup-matcher/${assetId}`, {}, buildAxiosRequestConfig(config))
    return result.data
  },
}

import Vue from 'vue'

// axios
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
// import router from '@/router'
import appConfig from '@/config'

const axiosIns = axios.create({
  baseURL: appConfig.apiBaseUrl,
})

axiosIns.interceptors.request.use(config => {
  const accessToken = store.getters['auth/accessToken']

  if (accessToken != null && !config.headers.Authorization) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`
  }

  return config
}, error => Promise.reject(error))

axiosIns.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    // store.dispatch('auth/signin', router.currentRoute.fullPath)

    // try to signin silent. If this fails, requireLoginPopup will show a message (@/views/auth/RequireLoginPopup.vue) and redirects to idsrv login page
    store.dispatch('auth/signinSilent')
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns

import Vue from 'vue'
import VueHighlightJS from 'vue-highlight.js'

// Highlight.js languages (Only required languages)
import javascript from 'highlight.js/lib/languages/javascript'

/*
* Import Highlight.js theme
* Find more: https://highlightjs.org/static/demo/
*/
import 'highlight.js/styles/default.css'

Vue.use(VueHighlightJS, {
  languages: {
    javascript,
  },
})

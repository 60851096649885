import Vue from 'vue'
import VueAppInsights from 'vue-application-insights'
import router from '@/router'

// Azure Application Insights tracking

const aiKey = process.env.VUE_APP_APPLICATION_INSIGHTS_INSTRUMENTATIONKEY

if (aiKey) {
  Vue.use(VueAppInsights, {
    baseName: 'PortalUi',
    id: aiKey,
    router,
  })
}

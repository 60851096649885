import { $themeConfig } from '@themeConfig'

const isVerticalMenuCollapsedKey = 'isVerticalMenuCollapsed'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
  },
  getters: {},
  mutations: {
    INIT(state) {
      if (localStorage.getItem(isVerticalMenuCollapsedKey)) {
        state.isVerticalMenuCollapsed = localStorage.getItem(isVerticalMenuCollapsedKey) === 'true'
      }
    },
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
      localStorage.setItem(isVerticalMenuCollapsedKey, val)
    },
  },
  actions: {},
}

import Vue from 'vue'
import { getOidcClient } from '@/auth/oidc/oidc-client'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import ProfileService from '@/service/profile.service'

const defaultState = {
  initialized: false,
  user: {},
  isOidcEventsHandled: false,
  userProfile: null,
}

let oidcClient = getOidcClient()

const actions = {
  init: context => {
    if (!context.getters.isInitialized) {
      // eslint-disable-next-line no-unused-vars

      // Automatically Silent renewes by oidc-client need to be catched to store user into VUEX Store
      oidcClient.events.addUserLoaded(user => {
        Vue.$log.debug('User loaded', user)
        context.commit('AUTHENTICATION_SUCCESS', user)
      })

      context.commit('INITIALIZED')
    }
  },
  impersonate: (context, userId) => {
    oidcClient.impersonate(userId)
  },
  cancelImpersonate: context => {
    if (context.getters.isUserImpersonated === true) {
      oidcClient.cancelImpersonation()
    }
  },
  impersonateRedirectCallback: context => {
    // After redirection, silently signin to get new impersonated token from Idsrv and force refreshing user profile
    context.dispatch('signinSilent')

    const handler = setInterval(() => {
      if (!context.getters.isLoading) {
        clearInterval(handler)
        oidcClient.redirectAfterImpersonation()
      }
    }, 100)
  },
  signinSilent: context => {
    context.commit('TOKEN_REQUEST_PENDING')
    oidcClient.signinSilent()
      .then(response => {
        context.commit('TOKEN_REQUEST_SUCCESS')
        context.dispatch('setAuthenticationSuccess', response)
      })
      .catch(error => {
        context.commit('TOKEN_REQUEST_FAILURE', error)
      })
  },
  signin: (context, returnPath) => {
    Vue.$log.info(`Signin and redirect to ${returnPath}`)
    return oidcClient.signinRedirect({ state: returnPath })
  },
  signinRedirectCallback: context => {
    context.commit('TOKEN_REQUEST_PENDING')
    oidcClient.signinRedirectCallback()
      .then(response => {
        context.commit('TOKEN_REQUEST_SUCCESS')
        context.dispatch('setAuthenticationSuccess', response)

        let returnUrl = '/'
        if (response.state) {
          returnUrl = response.state
        }

        router.push({ path: returnUrl })
      })
      .catch(error => {
        context.commit('TOKEN_REQUEST_FAILURE', error)
      })
  },
  signinSilentCallback: context => {
    context.commit('TOKEN_REQUEST_PENDING')
    oidcClient.signinSilentCallback()
      .then(response => {
        context.commit('TOKEN_REQUEST_SUCCESS')
        context.dispatch('setAuthenticationSuccess', response)
      })
      .catch(error => {
        context.commit('TOKEN_REQUEST_FAILURE', error)
      })
  },
  setAuthenticationSuccess: (context, user) => {
    // Check if library is suscribed to OIDC library events
    if (!context.state.isOidcEventsHandled) {
      oidcClient.events.addAccessTokenExpired(() => {
        // TODO: Handliing token expiration
      })
      context.commit('OIDC_LIBRARY_EVENTS_HANDLED')
    }
    context.commit('AUTHENTICATION_SUCCESS', user)

    // Refresh user if userProfile is empty (after login)
    if (!context.state.userProfile) {
      context.dispatch('refreshUserProfile')
    }
  },
  refreshUserProfile: context => {
    ProfileService.getMyProfileAsync().then(response => {
      context.commit('SET_USERPROFILE', response)
    })
  },
  signOut: context => {
    context.commit('LOGOUT_REQUEST_PENDING')

    oidcClient.clearStaleState()
      .then(() => {
        Vue.$log.info('OIDC clear state state success')
      })
      .catch(e => {
        Vue.$log.info('OIDC clear state state error', e.message)
      })

    oidcClient.signoutRedirect()
      .then(() => {
        context.commit('LOGOUT_REQUEST_SUCCESS')
      })
      .catch(error => {
        context.commit('LOGOUT_REQUEST_FAILURE', error)
      })
  },
  checkAccess: (context, requiresAuth) => new Promise(resolve => {
    oidcClient = getOidcClient()
    if (!requiresAuth) return resolve('OK')
    // Check if we have a token in Session Storage
    const getUserPromise = new Promise(userResolve => {
      oidcClient.getUser().then(user => {
        userResolve(user)
      }).catch(() => {
        userResolve(null)
      })
    })
    let statusCode = 'UNAUTHORIZED'
    getUserPromise.then(user => {
      // Check if we have token information and if token is not expired
      if (user && !user.expired) {
        statusCode = 'OK'
        context.dispatch('setAuthenticationSuccess', user)
      }
      resolve(statusCode)
    })

    return resolve
  }),
}

const mutations = {
  INITIALIZED: state => {
    state.initialized = true
  },
  TOKEN_REQUEST_PENDING: state => {
    state.user = { loading: true }
  },
  TOKEN_REQUEST_SUCCESS: state => {
    state.user = { loading: false }
  },
  TOKEN_REQUEST_FAILURE: (state, error) => {
    state.user = { error }
  },
  LOGOUT_REQUEST_PENDING: state => {
    state.user = { loading: true }
  },
  LOGOUT_REQUEST_SUCCESS: state => {
    state.user = {}

    // Unset MATOMO user id
    // eslint-disable-next-line no-undef
    _paq.push(['resetUserId'])
  },
  LOGOUT_REQUEST_FAILURE: (state, error) => {
    state.user = { error }
  },
  AUTHENTICATION_SUCCESS: (state, user) => {
    state.user = user
  },
  OIDC_LIBRARY_EVENTS_HANDLED: state => {
    state.isOidcEventsHandled = true
  },
  SET_USERPROFILE: (state, userProfile) => {
    state.userProfile = userProfile

    // Set MATOMO user id
    if (userProfile.tenantId === null) { // set userId tracking only for HOST users
      // eslint-disable-next-line no-undef
      _paq.push(['setUserId', userProfile.email])
    }
  },
}

const getters = {
  isInitialized(state) {
    return state.initialized
  },
  accessToken(state) {
    return state.user.access_token
  },
  isLoading(state) {
    return (state.user != null && state.user.loading)
  },
  tokenResponse(state) {
    return (state.user) ? state.user : {}
  },
  isUserImpersonated(state) {
    if (state.user.profile) {
      return state.user.profile.impersonator_userid != null
    }
    return false
  },
  /* userProfile(state) {
    return (state.user && state.user.profile != null) ? state.user.profile : {}
  }, */
  userIsAuthenticated(state) {
    return (state.user && state.user.id_token != null)
  },
  userProfile(state) {
    return state.userProfile ?? {}
  },
  isHost(state) {
    if (state.user.profile) {
      return !state.user.profile.tenantid
    }
    return false
  },
  isSystemAdmin(state) {
    const systemAdminRoleName = 'SystemAdmin'
    if (state.user.profile) {
      if (typeof state.user.profile.role === 'string') {
        return state.user.profile.role === systemAdminRoleName
      }
      if (Array.isArray(state.user.profile.role)) {
        return state.user.profile.role.some(x => x === systemAdminRoleName)
      }
    }
    return false
  },
  isTenantAdmin(state) {
    const tenantAdminRoleName = 'TenantAdmin'
    if (state.user.profile) {
      if (typeof state.user.profile.role === 'string') {
        return state.user.profile.role === tenantAdminRoleName
      }
      if (Array.isArray(state.user.profile.role)) {
        return state.user.profile.role.some(x => x === tenantAdminRoleName)
      }
    }
    return false
  },
  grantedPermissions(state) {
    if (state.userProfile) {
      return state.userProfile.grantedPermissions
    }
    return []
  },
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
}

// eslint-disable-next-line import/no-cycle
import assetService from '@/service/asset.service'

const defaultState = {
  assets: [],
  isLoading: false,
}

const actions = {
  async fetchAllAssets({ commit }, config) {
    commit('SET_ISLOADING', true)

    const result = await assetService.getAllListAsync(config.options, config.requestConfig)

    commit('SET_ASSETS', result)
    commit('SET_ISLOADING', false)
  },
}

const mutations = {
  SET_ASSETS(state, assets) {
    state.assets = assets
  },
  SET_ISLOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_ASSET_TODAYCHECKUPSTATUS(state, { assetId, todayCheckupStatus }) {
    const asset = state.assets.find(x => x.id === assetId)
    if (asset != null) {
      asset.todayCheckupStatus = todayCheckupStatus
    }
  },
  SET_ASSET_CHECKUPLOADING(state, { assetId, isLoading }) {
    const asset = state.assets.find(x => x.id === assetId)
    if (asset != null) {
      asset.checkupLoading = isLoading
    }
  },
}

const getters = {
  assets: state => state.assets,
  isLoading: state => state.isLoading,
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
}

// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  async getMyProfileAsync() {
    const result = await axios.get('/core/my-profile')
    return result.data
  },

  async updateAsync(data) {
    const result = await axios.put('/core/my-profile', data)
    return result.data
  },

  async updatePasswordAsync(currentPassword, newPassword) {
    const result = await axios.post('/core/my-profile/change-password', {
      currentPassword,
      newPassword,
    })
    return result.data
  },

  async requestEmailChangeAsync(passwordConfirmation, email) {
    const result = await axios.post('/core/my-profile/change-email/request', {
      passwordConfirmation,
      email,
    })
    return result.data
  },

  async confirmEmailChangeAsync(confirmationCode) {
    const result = await axios.post('/core/my-profile/change-email/confirm', {
      confirmationCode,
    })
    return result.data
  },

  async updateProfileImage(image) {
    const formData = new FormData()
    formData.append('content', image)

    const result = await axios.put('/core/my-profile/image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return result.data
  },

  async deleteProfileImage() {
    const result = await axios.delete('/core/my-profile/image')
    return result.data
  },

  async requestEmailConfirmationAsync() {
    const result = await axios.post('/core/my-profile/confirm-email/request')
    return result.data
  },

  async confirmEmailAsync(confirmationCode) {
    const result = await axios.post('/core/my-profile/confirm-email/confirm', {
      confirmationCode,
    })
    return result.data
  },
}

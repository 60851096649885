function getRunTimeConfig() {
  if (typeof window.runtimeConfig === 'object') {
    return window.runtimeConfig || {}
  }

  return {}
}

export default Object.freeze({
  oidcAuthority: process.env.VUE_APP_OIDC_AUTHORITY,
  oidcClientId: process.env.VUE_APP_OIDC_CLIENTID,
  oidcScope: process.env.VUE_APP_OIDC_SCOPE,
  apiBaseUrl: process.env.VUE_APP_API_BASEURL,
  applicationInsightsKey: process.env.VUE_APP_APPLICATION_INSIGHTS_INSTRUMENTATIONKEY,
  zammadTicketUrl: process.env.VUE_APP_ZAMMAD_TICKET_URL,
  matomoSiteId: process.env.VUE_APP_MATOMO_SITEID,
  matomoHost: process.env.VUE_APP_MATOMO_HOST,
  version: process.env.PACKAGE_VERSION,
  environmentName: process.env.NODE_ENV,
  isProduction: () => process.env.NODE_ENV === 'production',

  /**
     * Allow config specified at run time to override everything above.
     */
  ...getRunTimeConfig(),
})

import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
let existingAbility = []

try {
  existingAbility = JSON.parse(localStorage.getItem('acl'))
// eslint-disable-next-line no-empty
} catch (e) {
  // TODO log
}

export default new Ability(existingAbility || initialAbility)

export default [
  {
    path: '/oidc-callback',
    name: 'Callback',
    component: () => import('@/views/auth/OidcAuthCallback.vue'),
    meta: {
      analyticsIgnore: true,
      layout: 'full',
      allowAnonymous: true, // user is not authenticated on that step, so allow anonymous access
    },
  },
  {
    path: '/oidc-silent-renew',
    name: 'Silent-Renew',
    component: () => import('@/views/auth/OidcSilentRenew.vue'),
    meta: {
      analyticsIgnore: true,
      layout: 'full',
      allowAnonymous: true, // user is not authenticated on that step, so allow anonymous access
    },
  },
  {
    path: '/oidc-logout',
    name: 'Logout',
    component: () => import('@/views/auth/Logout.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true, // user is not authenticated on that step, so allow anonymous access
    },
  },
  {
    path: '/impersonate-callback',
    name: 'Impersonate',
    component: () => import('@/views/auth/ImpersonateCallback.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/access-restricted',
    name: 'NoAccess',
    component: () => import('@/views/auth/NoAccess.vue'),
    meta: {
      layout: 'full',
      allowAnonymous: true,
    },
  },
]

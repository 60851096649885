import ability from './ability'

// export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
export function canNavigate(to) {
  if (to.matched.some(route => route.meta.action)) {
    return to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
  }

  return true
}

export const _ = undefined
